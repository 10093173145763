$(document).ready(function() {
    $('.centerSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        dots: true,
        arrows:false,
    });

    // $('.listSlider').slick({
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     autoplay: false,
    //     autoplaySpeed: 2000,
    //     dots: true,
    //     arrows:false,
    // });




});
